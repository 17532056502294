import React, { createContext, useContext, useEffect, useMemo, useState, Dispatch } from 'react'
import { ZOOM_IMAGES_PAGES } from '~/data/pages'
import useMediaQuery from '~/hooks/use-media-query'
import { useCustomerContext } from './customer-context'

type CollectionContextType = {
  showZoomImageMobile: boolean
  setSelectorSize: Dispatch<React.SetStateAction<number | null>>
  selectorSize: number | null
}

const CollectionContext = createContext<CollectionContextType>({
  showZoomImageMobile: false,
  setSelectorSize: a => null,
  selectorSize: null,
})

export const useCollectionContext = () => useContext(CollectionContext)

export const CollectionProvider = ({
  children,
  collectionHandle,
}: {
  collectionHandle: string
  children: React.ReactNode
}) => {
  const { isTrade } = useCustomerContext()
  const [showZoomImageMobile, setShowZoomImageMobile] = useState(false)
  const includeZoomImages = useMemo(
    () => ZOOM_IMAGES_PAGES.includes(collectionHandle) || collectionHandle.includes('rug'),
    [collectionHandle]
  )
  const [selectorSize, setSelectorSize] = useState<number | null>(null)

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  useEffect(() => {
    if (includeZoomImages && (isTrade || isMobile)) {
      setShowZoomImageMobile(true)
    } else {
      setShowZoomImageMobile(false)
    }
  }, [isMobile, includeZoomImages])

  return (
    <CollectionContext.Provider value={{ showZoomImageMobile, setSelectorSize, selectorSize }}>
      {children}
    </CollectionContext.Provider>
  )
}
