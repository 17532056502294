/** @jsx jsx */
import { jsx, Box, Grid, AspectRatio, Text, Heading, Image } from 'theme-ui'
import { FC } from 'react'
import { Link } from 'gatsby'
import { ProductItemComponentType } from '~/@types/models'
import ImageWrapper from '~/components/image-wrapper'
import urls from '~/common/urls'
import { space } from '~/gatsby-plugin-theme-ui'

type Props = {
  product: ProductItemComponentType
  buttonText?: string
}

const ProductItemFabrics: FC<Props> = ({ product, buttonText = 'Shop All Colorways' }) => {
  const productLink = `${urls.pages.productDetail}/${product.handle}`
  const mainImage = product.mainImageLifestyle
  return (
    <Grid
      columns={[1, 1, null, 2, 2]}
      sx={{
        alignItems: 'center',
        flexDirection: ['column', null, 'unset'],
      }}
    >
      <Box
        sx={{
          width: ['100%', null, null, null, '100%'],
          height: ['auto', null, '100%'],
        }}
      >
        {mainImage ? (
          <Link
            to={productLink}
            sx={{
              height: '100%',
              width: '100%',
              display: 'block',
              div: {
                height: '100%',
              },
            }}
          >
            <ImageWrapper ratio={920 / 528} center>
              <Image
                src={mainImage.srcDesktop}
                loading="lazy"
                sx={{
                  width: '100%',
                  minHeight: '100%',
                  objectFit: 'cover',
                }}
                alt={mainImage.altText || product.title || 'Product Image'}
              />
            </ImageWrapper>
          </Link>
        ) : (
          <AspectRatio
            ratio={920 / 528}
            sx={{
              backgroundColor: 'dark',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          pl: [0, null, null, space.l1],
          width: ['100%', null, null, null, '650px'],
          maxWidth: ['100%', null, null, 664 + space.xl2 * 2],
          display: ['flex', null, null, 'block'],
          justifyContent: 'space-between',
        }}
      >
        <Heading
          variant="title5"
          sx={{
            fontSize: ['12px', '15px', null, '22px'],
            lineHeight: ['38px', null, '38px'],
            fontFamily: 'Roboto',
            textTransform: 'uppercase',
            mb: [0, null, null, space.sm * 2.5],
          }}
        >
          {product.title}
        </Heading>
        <Text
          variant="xsmall"
          sx={{
            mb: [space.md2, null, space.l1],
            display: ['none', 'none', null, 'block'],
            color: 'textMid',
          }}
        >
          {product.description}
        </Text>
        <Link
          to={productLink}
          sx={{
            variant: `buttons.link`,
            width: 'auto',
            justifyContent: 'Initial',
            padding: 0,
            textDecoration: 'underline',
            textUnderlineOffset: '7px',
            display: ['block'],
            textTransform: 'uppercase',
            alignSelf: ['center'],
            fontSize: ['8px', '11px', null, '16px'],
          }}
        >
          {buttonText}
        </Link>
      </Box>
    </Grid>
  )
}

export default ProductItemFabrics
