export const ZOOM_IMAGES_PAGES = [
  'luxe-collection',
  'rugs',
  'in-stock-rug-shop-all',
  'new-rugs',
  'antique-rugs',
  'oversized-rugs',
  'rug-pads',
  'rugs-sale',
]
